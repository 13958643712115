@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import './consts/_colors.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

h1 {
  padding: 10px 5px;
  text-align: center;
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  box-sizing: border-box;
  // Firefox-only
  scrollbar-width: thin; // "auto" or "thin"
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

button:disabled {
  cursor: not-allowed;
}

::-webkit-scrollbar {
  max-width: 0.6rem;
  max-height: 0.6rem;
  background: none;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.3rem rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
}

// ::selection {
//   background: #17a2b8;
//   color: white;
// }

.App {
  height: 100vh;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;

  .main {
    flex-grow: 1;
  }
}

table {
  font-size: 0.9rem;
}

table th {
  text-align: center;
}

td button.btn.btn-sm {
  line-height: 0.4rem;
  padding: 0.4rem 1rem;
}

nav .pagination {
  margin-left: auto;
  margin-right: auto;
  width: min-content;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btn-accept {
  color: white;
  background-color: RGBA(31, 152, 80, 1);
}

.btn-cancel {
  color: white;
  background-color: RGBA(244, 89, 80, 1);
}

.table {
  color: black;
}

.table-responsive {
  text-align: center;
}

.table-responsive td {
  cursor: text;
}

.table-responsive th {
  vertical-align: middle;
}

.table thead tr:hover {
  color: black;
}

.table-hover tbody tr:hover {
  color: black;
}

tbody {
  padding: 2rem 1rem;

  tr {
    transition: .3s ease-in-out;

    &:nth-child(odd) {
      background-color: #ECECF1;
    }

    &:hover {
      background-color: darken(#ECECF1, 3%);
    }
  }

  &:nth-child(odd) {
    tr {
      background-color: #bebebe;
    }
  }
}

.table-striped {
  tbody:nth-of-type(odd) {
    tr {
      background-color: #bebebe !important;
    }
  }
}

.form-control {
  border-width: 2px;
  border-style: solid;
  border-color: #66ABC9;
  height: 45px !important;
  background-color: #F8F9FA;
  transition: .3s ease-in-out;

  &:focus {
    border-color: #1A82AE;
    box-shadow: none;
  }
}

.dropdown-toggle {
  display: flex;
  align-items: center;
}

.dropdown-toggle.btn.btn-info,
.dropdown-toggle.btn.btn-success,
.dropdown-toggle.btn.btn-danger,
.btn.btn-info,
.btn.btn-success,
.btn.btn-primary,
.btn.btn-danger {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fa, .fab, .fad, .fal, .far, .fas {
  display: inline !important;
}

.text-center {
  text-align: center;
}

.title {
  text-align: center;
  margin-bottom: 15px;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;

  &:not([class*='btn-light']) {
    min-width: 140px;
  }

  &:first-of-type {
    margin-right: 8px;
  }
}

.select {
  min-width: 100px;
  height: 45px;
  padding: 3px 15px;
  border: 2px solid $aquaColor;
  background-color: $bgInputColor;
  color: $lightGreyText;
  font-size: 14.5px;
  text-align: center;
  text-overflow: ellipsis;
  letter-spacing: 1px;
  transition: .3s ease-in-out;
}

.react-tooltip-lite {
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: .4rem;
}

.react-tooltip-lite-arrow {
  border-color: rgba(0, 0, 0, 0.8);
}
