$aquaColor: #66ABC9;
$aquaDarkColor: #1A82AE;
$disableColor: #dad7d7;
$statusPassedColor: #5acc5a;
$statusCrashedColor: #f89999;
$statusFailedColor: #faef7d;
$statusFailedEnsureColor: #a39a32;
$borderColor: #66ABC9;
$statusInitialCrashedColor: #c0c0c0;
$statusPassedWarningsColor: #98da98;
$progressGreenColor: #008000;
$errorColor: #ef080d;
$whiteColor: #fff;
$blackColor: #0e0202cc;
$bgInputColor: #F8F9FA;

$lightGreyText: #808490;

// new colors FIFMA
$successTooltip: #c2d5ff;
$errorTooltip:  #FFBAC7;
$warningTooltip: #FFEBBF;
