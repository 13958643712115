$bp-small: 37.5em; // 600px/16 = 37.5em

@mixin sidebarIcon {
  i {
    color: #999;
    transition: all 0.2s;
  }
  &:hover {
    i {
      color: #333;
    }
  }
}

.Sidebar {
  flex-basis: 10%;
  flex-shrink: 0;
  background-color: #f8f9fa;
  // overflow-x: hidden;
  // overflow-y: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  &.hidden {
    flex-basis: 46px;
    .list-group {
      display: none;
    }
  }
  .toggle {
    margin: 0.5rem 0;
    i {
      transition: transform 0.2s;
    }
  }
  .list-group-item {
    cursor: pointer;

    @include sidebarIcon;
  }

  .dropdown-toggle {
    width: 100%;
    padding: 0.75rem 1.25rem;
    font-weight: inherit;
    font-size: inherit;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    @include sidebarIcon;

    &::after {
      position: absolute;
      right: 0.5rem;
    }
  }

  .dropdown-toggle,
  .dropdown-item {
    &:hover,
    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: #fff;
    }
  }

  .dropdown-menu {
    padding: 0;
    font-size: inherit;
    border: none;

    & .list-group-item {
      border-left: none;
    }
  }
}
