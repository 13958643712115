.Header {
  .navbar {
    padding: 0.6rem;
    .navbar-brand {
      font-size: 1rem;
    }
    .search {
      flex-grow: 1;
      display: flex;
      flex-wrap: nowrap;

      // margin-right: 2rem;
      .form-control {
        flex-grow: 1;
        // font-size: 14px;
        margin-right: 0.4rem;
        padding: 0.4rem 1rem;
        color: #fff;
        background-color: rgba(255, 255, 255, 0.1);
        border-color: rgba(255, 255, 255, 0.1);
        height: 35px;
        &:focus {
          color: #495057;
          background-color: #fff;
          border-color: transparent;
          box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
          outline: 0;
        }
      }
    }
    .user {
      display: flex;
      img {
        margin-right: 0.2rem;
        margin-left: 0.4rem;
        height: 40px;
        width: 40px;
      }
    }
  }
}
