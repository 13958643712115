@import '../../consts/colors';

.notif-header {
    font-weight: 400;

    background-color: transparent;
    border-radius: 2px;
    color: $blackColor;
}
.notif-container {
    font-family:  'Roboto', sans-serif;
    color: $blackColor;
    padding: 10px;
    width: 350px;
    border-radius: 5px;

    background-color: azure;
}

.notif-body {
    hyphens: manual;
    overflow:hidden;
    word-wrap: break-word;
    word-break: break-all;
}

.bgd-success {
    background-color: $successTooltip;
}

.bgd-warning {
    background-color: $warningTooltip;
}

.bgd-error {
    background-color: $errorTooltip;
}